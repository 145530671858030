import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useLocation } from "components-care";
function withLocation(Component) {
    return function WithLocationHOC(props) {
        return _jsx(Component, Object.assign({}, props, { location: useLocation() }));
    };
}
const CHUNK_LOAD_ERROR = "ChunkLoadError";
const ERROR_CUSTOM_MESSAGES = {
    [CHUNK_LOAD_ERROR]: "errors.chunk-load-error",
};
const ERROR_IGNORED = [CHUNK_LOAD_ERROR];
class ErrorBoundary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }
    static getDerivedStateFromError(error) {
        if (!ERROR_IGNORED.includes(error.name)) {
            Sentry.captureException(error);
        }
        return {
            error: error,
        };
    }
    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                error: null,
            });
        }
    }
    getMessage() {
        const { error } = this.state;
        if (!error)
            return "";
        if (error.name in ERROR_CUSTOM_MESSAGES) {
            return this.props.t(ERROR_CUSTOM_MESSAGES[error.name]);
        }
        return error.message;
    }
    render() {
        const { t } = this.props;
        const { error } = this.state;
        if (error) {
            return (_jsx(Grid, { container: true, direction: "row", justifyContent: "center", alignItems: "center", style: { height: "100%" }, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { variant: "h1", align: "center", children: t("errors.js.title") }), _jsx(Typography, { align: "center", children: this.getMessage() })] }) }));
        }
        return this.props.children;
    }
}
export default withTranslation("common")(withLocation(ErrorBoundary));
